import * as AppConstants from "../../../configurations/AppConstants.js";
import React, { useContext, useEffect, useState } from "react";
import { ScrollBarContext } from "../../../context/scrollbar-context.js";
import { permissionServices } from "../../../services/PermissionServices.js";
import "./ContextualTraining.css";
import TrainFiles from "../TrainFiles/TrainFiles.jsx";
import TrainURL from "../TrainURL/TrainURL.jsx";
import axios from "axios";
import { BMP_BE } from "../../../configurations/ServerCreds.js";
import { toast_notify } from "../../../services/ToastServices.js";
import Swal from "sweetalert2";
import { loader } from "../../../services/LoaderSerivces.js";

const ContextualTraining = () => {
  const [tableData, setTableData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [tablePageCount, setTablePageCount] = useState(0);
  const [tableRowPerPageCount, setTableRowPerPageCount] = useState(10);
  let count = 10;
  let page = 0;

  const [totalElements, setTotalElements] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  let { userRolePermission } = useContext(ScrollBarContext);

  //permission for downloading
  useEffect(() => {
    (function getPermission() {
      let obj = {
        moduleName: "Intelligence",
        userPermissionData: userRolePermission,
      };

      permissionServices(obj).then((res) => {
        // setDisableDownload(res.read);
      });
    })();
  }, []);

  const getTrainedFiles = (page, count) => {
    loader("start");
    return axios
      .get(
        `${BMP_BE}trainingModel/v1/getAllFiles?botId=${sessionStorage.getItem(
          "botId"
        )}&page=${page}&records=${count}`
      )
      .then((res) => {
        loader("stop");
        setTableData(res.data.dtos);
        setTotalElements(res.data.totalCount);
      })
      .catch((err) => {
        loader("stop");
        console.log(err);
        toast_notify(err?.response?.data?.errorDescription, "error");
      });
  };

  const deletAllTrainingData = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete all training data?",
      showCancelButton: true,
      confirmButtonColor: "#2196f3",
      cancelButtonColor: "#6c757d",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    })
      .then((result) => {
        if (result.value) {
          loader("start");
          axios
            .post(
              `${BMP_BE}openAi/v1/deleteAll?botId=${sessionStorage.getItem(
                "botId"
              )}`
            )
            .then((res) => {
              loader("stop");
              toast_notify("Files deleted successfully", "success");
              getTrainedFiles(0, 10);
            })
            .catch((err) => {
              loader("stop");
              toast_notify(err?.response?.data?.errorDescription, "error");
              console.log(err);
            });
        }
      })
      .catch((err) => {
        toast_notify(err?.response?.data?.errorDescription, "error");
        console.log(err);
      });
  };

  return (
    <div className="contextual-training-wrap">
      <div className="sub-title d-flex justify-content-between">
        <div className="left-title-section d-flex align-items-center ">
          <h4 className="m-0 pull-left ">Contextual Training</h4>
          <div className="contextual-labels bg-green d-flex align-items-center justify-content-center ">
            <img src="/bmpadmin/assets/images/llm-img.png"></img>
            <span>LLM Provider</span>
            <span className="contextual-labels-val cl-green">Open AI</span>
          </div>
          <div className="contextual-labels bg-blue d-flex align-items-center justify-content-center ">
            <img src="/bmpadmin/assets/images/model-img.png"></img>
            <span>Model Type</span>
            <span className="contextual-labels-val cl-blue">GPT 3.5</span>
          </div>
        </div>
        <div
          className={`btn btn-primary erase-btn${!tableData.length ? " btnDisable" : ""
            }`}
          onClick={() => deletAllTrainingData()}
        >
          Erase Training
        </div>
      </div>

      <div className="container-fluid" id="main-window-scroll">
        <ul className="inner-tabs d-flex">
          <li
            data-toggle="tab"
            className={`inner-tab-item ${activeTab === 0 ? "active" : ""}`}
            onClick={() => handleTabClick(0)}
          >
            Train Files
          </li>
          <li
            data-toggle="tab"
            className={`inner-tab-item ${activeTab === 1 ? "active" : ""}`}
            onClick={() => handleTabClick(1)}
          >
            Train URL
          </li>
        </ul>
        {activeTab === 0 && (
          <TrainFiles
            page={page}
            count={count}
            tableData={tableData}
            tablePageCount={tablePageCount}
            setTablePageCount={setTablePageCount}
            tableRowPerPageCount={tableRowPerPageCount}
            setTableRowPerPageCount={setTableRowPerPageCount}
            totalElements={totalElements}
            getTrainedFiles={getTrainedFiles}
          />
        )}
        {activeTab === 1 && <TrainURL />}
      </div>
    </div>
  );
};

export default ContextualTraining;
