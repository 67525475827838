import * as AppConstants from "../../../configurations/AppConstants.js";
import React, { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { TableLoader } from "../../../container/ContentLoaderComponent/TableLoader.jsx";
import ContextualTrainingPopup from "../ContextualTrainingPopup/ContextualTrainingPopup.jsx";

const TrainFiles = (props) => {
  //   let { userRolePermission } = useContext(ScrollBarContext);
  const [apiLoader, setApiLoader] = useState(false);

  const [showAddFileModal, setShowAddFileModal] = useState(false);
  //   //permission for downloading
  //   useEffect(() => {
  //     (function getPermission() {
  //       let obj = {
  //         moduleName: "Intelligence",
  //         userPermissionData: userRolePermission,
  //       };

  //       permissionServices(obj).then((res) => {
  //         // setDisableDownload(res.read);
  //       });
  //     })();
  //   }, []);

  useEffect(() => {
    props.getTrainedFiles(0, 10);
  }, []);

  const columns = [
    {
      name: "fileName",
      label: "File Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "dataSpace",
      label: "Space Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "readableSize",
      label: "Size",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "uploadDate",
      label: "Upload Details",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="biorow">
              <div className="biocol rights-bio">
                <span className="date timeago" title={value}>
                  {new Intl.DateTimeFormat("en-GB", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                    hour: "numeric",
                    hourCycle: "h12",
                    minute: "numeric",
                    second: "numeric",
                  }).format(new Date(value))}
                </span>
              </div>
            </div>
          );
        },
      },
    },
  ];

  //theme styling
  const getMuiTheme = () => {
    return createTheme({
      typography: {
        useNextVariants: true,
      },
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            fixedHeader: {
              padding: "0px !important",
              fontSize: "0.750rem!important",
            },
            data: {
              textTransform: "none",
              fontSize: "0.750rem!important",
            },
            toolButton: {
              marginLeft: "0px!important",
              paddingLeft: apiLoader ? "0px!important" : "15px!important",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              padding: "0px !important",
            },
          },
        },
        MUIDataTablePagination: {
          styleOverrides: {
            root: {
              "&:last-child": {
                padding: "0px 24px 0px 0px",
              },
            },
            navContainer: {
              float: "left",
            },
            toolbar: {
              paddingLeft: apiLoader ? "0px!important" : "16px!important",
              opacity: "0.7",
              ">*": {
                fontSize: "0.750rem!important",
              },
            },
            tableCellContainer: {
              padding: "0px 24px 0px 0px!important",
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              borderBottom: "0px",
            },
          },
        },
      },
    });
  };

  const options = {
    ...AppConstants.DATATABLE_DEFAULT_OPTIONS,
    selectableRowsOnClick: false,
    selectedRows: false,
    print: false,
    search: false,
    download: false,
    viewColumns: false,
    filter: false,
    count: props.totalElements,
    // rowsPerPage: 10,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: apiLoader ? (
          <TableLoader />
        ) : (
          "Sorry, no matching records were found"
        ),
      },
    },

    pagination: props.tableData.length ? true : false,
    onTableChange: (action, tableState) => {
      if (action === "propsUpdate") {
        tableState.page = props.tablePageCount;
        tableState.rowsPerPage = props.tableRowPerPageCount;
      }
      switch (action) {
        case "changePage":
          props.setTablePageCount(tableState.page);
          props.setTableRowPerPageCount(tableState.rowsPerPage);
          props.getTrainedFiles(tableState.page, tableState.rowsPerPage);
          break;
        case "changeRowsPerPage":
          props.setTablePageCount(tableState.page);
          props.setTableRowPerPageCount(tableState.rowsPerPage);
          props.getTrainedFiles(tableState.page, tableState.rowsPerPage);
          break;
        default:
          console.log("action not supported");
      }
    },
  };

  const toggleAddFileModal = () => {
    setShowAddFileModal(!showAddFileModal);
  };

  return (
    <>
      {showAddFileModal && (
        <ContextualTrainingPopup
          showAddFileModal={showAddFileModal}
          setShowAddFileModal={setShowAddFileModal}
          getTrainedFiles={props.getTrainedFiles}
        />
      )}
      <div className="add-btn-wrap" onClick={() => toggleAddFileModal()}>
        Add Files
      </div>
      <div className="" id="main-window-scroll">
        <div className="table-container datatable">
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              // title={"Track Changes"}
              data={props.tableData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </div>
      </div>
    </>
  );
};

export default TrainFiles;
