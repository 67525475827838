import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createBrowserHistory } from "history";

const withRouter = (WrappedComponent) => {
    const history = createBrowserHistory();
    return (props) => {
        const location = useLocation();
        const navigate = useNavigate();
        return <WrappedComponent {...props} location={location} navigate={navigate} history={history} />;
    };
};
export default withRouter;