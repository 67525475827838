import React from "react";
import { toast } from "react-toastify";

export const CURRENT_SERVER = "http://localhost:8080/";

export const toast_notify = (message, type) => {
  if (type === "success" || type === "Success") {
    return toast.success(
      message
    );
  } else if (type === "error" || type === "failure" || type === "Failure") {
    return toast.error(
      message
    );
  } else if (type === "info") {
    return toast.info(
      message
    );
  } else {
    return toast.warn(
      message
    );
  }
};
