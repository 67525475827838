import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    feedbackFormData: {
        commentConditionVal: [{ index: 0, experienceType: "", selectCondition: "", selectedValue: "" }],
        logicalOperator: "",
        expValue: {
            key5: "Excellent",
            key4: "Very Good",
            key3: "Medium",
            key2: "Poor",
            key1: "Very Bad"
        },
        showFeedbackConfigForm: false,
        showFeedbackConfigChanges: false,
        textFieldsData: [{
            indexValue: 0,
            textFieldDisplayText: "",
            textFieldPlaceholderText: "",
            isMandatory: true,
            validationType: ""
        }]
    }
}

const feedbackFormSlice = createSlice({
    name: 'feedbackForm',
    initialState: initialState,
    reducers: {

        //toggle for preview 
        toggleFeedbackConfigChanges: (state, { payload }) => {
            state.feedbackFormData.showFeedbackConfigChanges = payload;
        },
        //toggle for showing feedback config screens
        toggleShowFeedbackConfig: (state, { payload }) => {
            state.feedbackFormData.showFeedbackConfigForm = payload;
        },

        //common function for all elements in comment conditions
        handleCommCondChanges: (state, { payload }) => {
            let newform = [...state.feedbackFormData.commentConditionVal]
            newform[payload.index][payload.name] = payload.value;
            state.feedbackFormData.commentConditionVal = newform
        },
        //toggle between logical operators and adding condition block
        handleOperatorChange: (state, { payload }) => {
            if (state.feedbackFormData.commentConditionVal.length < 2) {
                state.feedbackFormData.commentConditionVal = [...state.feedbackFormData.commentConditionVal, { index: 1, experienceType: "", selectCondition: "", selectedValue: "" }]
            }
            state.feedbackFormData.logicalOperator = payload
        },
        //removing conditional block
        removeConditionalBlock: (state, { payload }) => {

            if (state.feedbackFormData.commentConditionVal.length > 1) {
                const newform = [...state.feedbackFormData.commentConditionVal];
                newform.splice(payload, 1);
                state.feedbackFormData.commentConditionVal = newform
            }
            state.feedbackFormData.logicalOperator = ""
        },
        //changing input values for experience type
        handleExpChanges: (state, { payload }) => {
            state.feedbackFormData.expValue = { ...state.feedbackFormData.expValue, [payload.name]: payload.value }
        },

        //common function for all elements in text fields
        handleTextFieldChanges: (state, { payload }) => {
            let newTextFieldData = [...state.feedbackFormData.textFieldsData];
            newTextFieldData[payload.index][payload.name] = payload.value;
            state.feedbackFormData.textFieldsData = newTextFieldData
        },

        //change radio button opt
        handleMandatoryOpt: (state, { payload }) => {
            let newTextFieldData = [...state.feedbackFormData.textFieldsData];
            newTextFieldData[payload.index][payload.name] = !state.feedbackFormData.textFieldsData[payload.index][payload.name];
            state.feedbackFormData.textFieldsData = newTextFieldData
        },
        //add text field
        addTextField: (state, { payload }) => {
            state.feedbackFormData.textFieldsData = [...state.feedbackFormData.textFieldsData, { indexValue: payload + 1, textFieldDisplayText: "", textFieldPlaceholderText: "", isMandatory: true }]
            // setTextFieldsData([...textFieldsData, { indexValue: index + 1, textFieldDisplayText: "", textFieldPlaceholderText: "", isMandatory: true }])
        },
        //remove text field
        deleteTextField: (state, { payload }) => {
            let newInputs = state.feedbackFormData.textFieldsData.filter((input, index) => index !== payload);
            // re-index the id property of each remaining input object
            newInputs.forEach((input, index) => {
                input.indexValue = index;
            });
            state.feedbackFormData.textFieldsData = newInputs;
        },

        // emptyTextFieldData: (state, { payload }) => {
        //     state.feedbackFormData.textFieldsData = []
        // },
        //updating radio button on getting data
        updateMandatoryOpt: (state, { payload }) => {
            let newTextFieldData = [...state.feedbackFormData.textFieldsData];
            newTextFieldData[payload.index][payload.name] = payload.value

            state.feedbackFormData.textFieldsData = newTextFieldData
        },
        //updating text fields when getting data
        getTextFields: (state, { payload }) => {
            state.feedbackFormData.textFieldsData = payload
        },
        //updating comment conditions when getting data
        getCommentConditions: (state, { payload }) => {
            state.feedbackFormData.commentConditionVal = payload
        }
    }
})

export const { handleCommCondChanges, handleOperatorChange, removeConditionalBlock, handleExpChanges, toggleShowFeedbackConfig, toggleFeedbackConfigChanges, handleTextFieldChanges, handleMandatoryOpt, addTextField, deleteTextField, updateMandatoryOpt, getTextFields, getCommentConditions } = feedbackFormSlice.actions;
export default feedbackFormSlice.reducer;



