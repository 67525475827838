import axios from "axios"
import { BMP_BE } from "../configurations/ServerCreds";

class NotificationAPI{
    fetchNotificationData(sessionId){
       return axios.get(`${BMP_BE}notification-board/v1/fetchNottificationDetails/${sessionId}`);
   }

   updateNotificationData(notificationId,botId,notificationData){
    return axios.post(`${BMP_BE}notification-board/v1/updateNotificationStatus/${notificationId}/${botId}`,notificationData)
   }
}
export default new NotificationAPI;