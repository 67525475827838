export const permissionServices = (requestObj) => {
  return new Promise((resolve, reject) => {
    const permissionObj = {
     read:true,
     write:true
    };

    if (Array.isArray(requestObj.userPermissionData) && requestObj.userPermissionData.length && requestObj.moduleName) {
      const getModuleUserPermission = requestObj.userPermissionData.find((modulePermission) => modulePermission.moduleName === requestObj.moduleName);
      if (getModuleUserPermission ) {
        permissionObj.read = !getModuleUserPermission.readPermission;
        permissionObj.write = !getModuleUserPermission.writePermission;
      }
      else
      {
        permissionObj.read = false;
        permissionObj.write = false;
      }
    }    
    resolve(permissionObj);
  });
}