import React from 'react';
import { useTimer } from 'react-timer-hook';

function IdleTimerCount({ autoLogout }) {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 300); // making the time duration for last 5 min after showing popup
  const {
    seconds,
    minutes,
  } = useTimer({
    expiryTimestamp: time, onExpire: () => {
      // console.log('onExpire called');
      autoLogout(true);
    }
  });

  // returns running time that starts from 5 min to 0 sec
  return (
    <>
      <span> {minutes} Minutes</span>  <span>{seconds} Seconds. </span>
    </>
  );
}

export default IdleTimerCount; 