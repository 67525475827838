export const nameValidation = "Only alphabets and underscore are allowed.";
export const nameLengthValidation = "Not more than 70 characters allowed.";
export const numericValidation = "Enter only numbers in seconds format.";
export const triggerCountValidation = "Trigger count should be greater than 0.";
export const requiredFieldError = "Required Filed.";
export const calendarRangeError = "From Date must be less than To Date.";
export const nonEmptyAttributeError = "Form should have at least one attribute with values. Please add values to the attribute before deleting another attribute in the Form.";
export const channelLinkSupportInfo = "This channel does not support links with alias name. So please type/paste URL directly in the Text Editor."

export const DEFAULT_WELOCME_MESSAGE =
  "Hi, this is your Botzer personal assistant , how can I help you?";
export const DEFAULT_THEME_ID = "100001";
export const DEFAULT_STATUS_ID = "10001";

export const valueType = "Text";
export const faqFileName = "faqQuestions.xlsx";
export const faqVariationFileName = "faqQuestionVariations.xlsx";

export const bulkIntentFileName = "BulkIntent.xlsx";
export const bulkUtteranceFileName = "BulkUtterance.xlsx";
export const bulkEntityTypeFileName = "BulkEntityType.xlsx";
export const bulkIntentEntityMapFileName = "BulkIntentEntityMap.xlsx";
export const multipleIntentUtteranceFileName = "MultipleIntentUtterance.xlsx";
export const bulkElementFileName = "BulkElement.xlsx";
export const bulkEntityValueCreationFileName = "BulkEntityValueMap.xlsx";

export const dialogValue = "2";
export const dialogLabel = "Form";
export const taskValue = "3";
export const taskLabel = "Task";
export const conversationNameValue = "15";
export const conversationNameLabel = "Conversation Flow";
export const carouselValue = "4";
export const carouselLabel = "Static Carousel";

export const activeId = "10001";
export const inActiveId = "10002";

export const andOperator = "AND";
export const orOperator = "OR";

export const themeConditionType = "5";
export const formAttributes = {
  "1": "buttons",
  "4": "carousals",
  "5": "links",
  "6": "images",
  "7": "messages",
  "9": "videos",
  "10": "dropdowns",
  "11": "textMessage.text",
};

export const dialogDropdownPlaceHolder = {
  value: "",
  label: "Select a Form Name",
};
export const taskDropdownPlaceHolder = {
  value: "",
  label: "Select a Task Name",
};
export const callbackActionTypeDropdownPlaceHolder = {
  value: "",
  label: "Select Action Type",
};
export const targetTypeDropdownPlaceHolder = {
  value: "",
  label: "Select Target Type",
};
export const conversationNameDropdownPlaceHolder = {
  value: "",
  label: "Select Conversation Name",
};
export const conversationQuestionDropdownPlaceHolder = {
  value: "",
  label: "Select Target Question",
};
export const carouselDropdownPlaceHolder = {
  value: "",
  label: "Select a carousel name",
};

export const selectChannelPlaceHolder = {
  value: "",
  label: "Select a Channel",
};

export const selectConditionVariablePlaceHolder = {
  value: "",
  label: "Select Condition Variable",
};

export const selectConditionTypePlaceHolder = {
  value: "",
  label: "Select Condition",
};

export const selectThemePlaceHolder = {
  value: "",
  label: "Select Theme",
};
export const csatExperienceData = [
  {
    value: "1001",
    label: "Smiley",
    url: "/bmpadmin/assets/images/feedback-experience-types/Smiley.png"
  },
  {
    value: "1002",
    label: "Stars",
    url: "/bmpadmin/assets/images/feedback-experience-types/Stars.png"
  },
  {
    value: "1003",
    label: "Bar",
    url: "/bmpadmin/assets/images/feedback-experience-types/Bar.png"
  },
  {
    value: "1004",
    label: "Numbers",
    url: "/bmpadmin/assets/images/feedback-experience-types/Numbers.png"
  }
]

export const npsExperienceData = [
  {
    value: "1001",
    label: "Square",
    url: "/bmpadmin/assets/images/feedback-experience-types/Square.png"
  },
  {
    value: "1002",
    label: "Circle",
    url: "/bmpadmin/assets/images/feedback-experience-types/Circle.png"
  },

]

export const MEDIA_ERROR = "Error in loading media";

// Data archival page constants

export const DATA_ARCHIVAL_PAGE_TITLE = "Data Archives"
export const FROM_DATE = "From Date"
export const TO_DATE = "To Date"
export const SELECT_PLACEHOLDER = "Select Channel"
export const SELECT_NOTIFICATION_TEXT = "Filter by Channel"
export const CURRENT = "Current"
export const RESET_FILTER = "Reset Filter"
export const REQUEST = "Request"
export const SELECT_CHANGES = "Select Category"
export const NO_DATE_SELECTED = "Select date to proceed"
export const DATE_INTERVAL_ERROR = "Date Interval Cannot be More Than 1 month"
export const MAIL_SUCCESS_RESPONSE = "Request for Mail has been sent"
export const DEFAULT_ERROR = "Error occured, please try again"

// Options will be enabled in future
export const selectOptions = [
  { value: "Test", label: "Test" },
  { value: "Test1", label: "Test1" },
];

// Channel default value
export const channelDefaultValue = [
  {
    label: "web",
    value: "web",
  },
]

export const industryData = [
  { industryId: "retail", industryValue: "Retail", description: "Chatbot assisting customers in finding products, processing orders, providing product recommendations, and handling customer service inquiries." },
  { industryId: "bankFin", industryValue: "Banking and Finance", description: "Chatbots assisting customers with account inquiries, transaction history, fund transfers, loan applications, and investment advice." },
  { industryId: "health", industryValue: "Healthcare", description: "Chatbot assists in scheduling appointments, provides basic medical advice, offers medication reminders, and assists in accessing healthcare information." },
  { industryId: "hospitality", industryValue: "Hospitality", description: "Chatbot assisting in handling hotel bookings, providing local recommendations, answer FAQs, and assist guests with room service requests." },
  { industryId: "eCommerce", industryValue: "E-commerce", description: "Chatbot assisting in facilitating product search, order tracking, payment assistance, and personalized recommendations based on browsing history." },
  { industryId: "edu", industryValue: "Education", description: "Chatbot supporting students with course selection, providing tutoring assistance, answering academic queries, and offer learning resources." },
  { industryId: "hr", industryValue: "Human Resources", description: "Chatbot streamlining the recruitment process, assist with employee onboarding, provide HR-related information, and manage leave requests." },
  { industryId: "travelTour", industryValue: "Travel and Tourism", description: "Chatbot helping users to book flights, hotels, and rental cars, offer travel tips, provide destination information, and assist with itinerary planning." },
  { industryId: "insurance", industryValue: "Insurance", description: "Chatbots helping users with insurance policy inquiries, claims processing, policy renewals, and insurance product comparisons." },
  { industryId: "teleCom", industryValue: "Telecommunications", description: "Chatbot assisting with account management, billing inquiries, technical support, service activation, and plan recommendations." },
  ];

export const createBotButtons = [
  {
    type: "Submit",
    name: "Create Bot",
    className: "btn btn-primary btn-sm",
    title: "Create Bot",
  },
  {
    type: "close",
    name: "Close",
    className: "btn btn-secondary btn-sm",
    title: "Close",
  },
];

export const createBotFields = [
  {
    label: "Bot Name",
    type: "input",
    name: "botName",
    value: "",
    placeHolder: "Enter Bot Name",
  },
  {
    label: "Bot Display Name",
    type: "input",
    name: "botDisplayName",
    value: "",
    placeHolder: "Enter Bot Display Name",
  },
  {
    label: "Bot Industry",
    type: "select",
    name: "industryName",
    value: "Select Industry",
    optionKeyId: "industryId",
    optionKeyValue: "industryValue",
    data: industryData,
    placeHolder: "Enter Bot Display Name",
    required: false
  },
  {
    label: "Bot Description",
    type: "textarea",
    required: false,
    name: "description",
    value: "",
    placeHolder: "Enter Bot Description",
  }
];

export const botCreationError = {
  "ClientError": "Accesskey or secretkey is incorrect",
  "EndpointConnectionError": "Nlp region is invalid for this credentials",
  "NotAllowed": "You are not allowed to create more than one bot in 14 days free trail",
  "Existing": "Bot already exist, please try with different bot name"
};

export const defaultBotCreationError = "Not able to create bot. Please try again later";

export const AllIntentSuccess = "All Intents Saved Successfully";
