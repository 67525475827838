import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { business_user, urlPath } from "../../configurations/ServerCreds";
import axios from "axios";
import { CURRENT_SERVER } from "../../configurations/ServerCreds";
import { withRouter } from "react-router";
import store from "../../redux/store";
import { connect } from "react-redux";
import { setStoreData } from "../../component/AuthenticationModule/fetchDataSlice";
import { DATA_ARCHIVAL_PAGE_TITLE } from "../../configurations/VariableConstants";
const rolename = sessionStorage.getItem("rolename");

const mapStateToProps = (state) => ({
  data: state.fetchDataSlice.data,
});
const mapDispatchToProps = (dispatch) => ({
  setStoreData: (data) => dispatch(setStoreData(data)),
});
class sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideReports: "DISABLED",
      hideDashboard: "DISABLED",
      hideLogDataModule: "DISABLED",
      hideUserModule: "DISABLED",
      hideStructuredModule: "DISABLED",
      hideUnstructuredModule: "DISABLED",
      hideBuildHistoryModule: "DISABLED",
      hideChannelModule: "DISABLED",
      hideFormsModule: "DISABLED",
      hideConvoModule: "DISABLED",
      hideCarouselModule: "DISABLED",
      hideThemeModule: "DISABLED",
      hideDropdownModule: "DISABLED",
      hideSystemVariableModule: "DISABLED",
      hideBotSettingsModule: "DISABLED",
      hideUserLogDetailsModule: "DISABLED",
      hideGPTModule: "DISABLED",
      hideDataArchivalModule: "DISABLED",
    };
  }
  componentDidMount() {
    this.getPermissionList();
  }

  closeChatBot = () => {
    document.getElementById("chat-bot").style.display = "none";
    document.getElementById("chat-opener").style.display = "block";
  };

  getPermissionList = () => {
    const storeData = store.getState();
    const rolename = sessionStorage.getItem("rolename");
    axios
      .get(CURRENT_SERVER + "getModulePermissionId/" + rolename)
      .then((res) => {
        const data = res.data;
        this.setState({
          hideDashboard: data.Dashboard,
          hideLogDataModule: data.Reporting,
          hideUserModule: data.Reporting,
          hideStructuredModule: data.Intelligence,
          hideUnstructuredModule: data.Intelligence,
          hideBuildHistoryModule: data.Intelligence,
          hideApiModule: data.APIIntegration,
          hideChannelModule: data.ChannelIntegration,
          hideFormsModule: data.Workflows,
          hideConvoModule: data.Workflows,
          hideCarouselModule: data.Workflows,
          hideDropdownModule: data.Workflows,
          hideSystemVariableModule: data.Settings,
          hideThemeModule: data.Settings,
          hideBotSettingsModule: data.Settings,
          hideUserLogDetailsModule: data.Reporting,
          hideGPTModule: data.ChatGPTPOC,
          hideDataArchivalModule: data.Reporting,
        });
        // res.data.nlp;
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  goToHomePage = () => {
    if (this.props.history) {
      this.props.navigate("/bmpadmin/botlist");
    }
  };

  render() {
    return (
      <div>
        <div className="sidebar">
          <div className="sidebar-inner">
            <div className="sidebar-logo">
              <div className="peers ai-c fxw-nw">
                <div className="peer peer-greed">
                  <a className="td-n">
                    <div className="peers ai-c fxw-nw">
                      <div className="peer">
                        <div className="logo">
                          <button
                            id="sidebar-toggle"
                            className="sidebar-toggle"
                          >
                            <i className="ti-menu"></i>
                            {/* <img className="sidebar-hamburger mt-0 ml-0" src={urlPath + "/assets/static/images/canvas_favicon.svg"} /> */}
                          </button>
                          <img
                            className="img-logo-align"
                            src={
                              urlPath +
                              "/assets/static/images/LTI_Cloud_Botzer.png"
                            }
                            alt="botzer"
                            onClick={this.goToHomePage}
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="peer">
                  <div className="mobile-toggle sidebar-toggle">
                    <a href="" className="td-n">
                      <i className="ti-arrow-circle-left" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <ul className="sidebar-menu scrollable pos-r">
              <li className="nav-item" onClick={this.closeChatBot}>
                <NavLink
                  exact
                  to={urlPath + "/botlist"}
                  className="sidebar-link"
                >
                  <span className="icon-holder">
                    <i className="c-blue-500 ">
                      <svg
                        height="512pt"
                        viewBox="0 0 512 512"
                        width="512pt"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m498.195312 222.695312c-.011718-.011718-.023437-.023437-.035156-.035156l-208.855468-208.847656c-8.902344-8.90625-20.738282-13.8125-33.328126-13.8125-12.589843 0-24.425781 4.902344-33.332031 13.808594l-208.746093 208.742187c-.070313.070313-.140626.144531-.210938.214844-18.28125 18.386719-18.25 48.21875.089844 66.558594 8.378906 8.382812 19.445312 13.238281 31.277344 13.746093.480468.046876.964843.070313 1.453124.070313h8.324219v153.699219c0 30.414062 24.746094 55.160156 55.167969 55.160156h81.710938c8.28125 0 15-6.714844 15-15v-120.5c0-13.878906 11.289062-25.167969 25.167968-25.167969h48.195313c13.878906 0 25.167969 11.289063 25.167969 25.167969v120.5c0 8.285156 6.714843 15 15 15h81.710937c30.421875 0 55.167969-24.746094 55.167969-55.160156v-153.699219h7.71875c12.585937 0 24.421875-4.902344 33.332031-13.808594 18.359375-18.371093 18.367187-48.253906.023437-66.636719zm0 0" />
                      </svg>
                    </i>
                  </span>
                  <span className="title" title="Go to Catalogue">
                    Home
                  </span>
                </NavLink>
              </li>

              <li className="nav-item dropdown tourBot-first-step">
                <a
                  className="dropdown-toggle"
                // hidden={
                //   this.state.hideDashboard === "DISABLED" &&
                //   this.state.hideLogDataModule === "DISABLED" &&
                //   this.state.hideUserModule === "DISABLED"
                // }
                >
                  <span className="icon-holder">
                    <i className="c-blue-500 ">
                      <svg
                        id="bold"
                        enableBackground="new 0 0 24 24"
                        height="512"
                        viewBox="0 0 24 24"
                        width="512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m4 6.75c0-2.619 2.131-4.75 4.75-4.75h9.133c-.329-1.151-1.378-2-2.633-2h-11.5c-1.517 0-2.75 1.233-2.75 2.75v15.5c0 1.517 1.233 2.75 2.75 2.75h.25z" />
                        <path d="m20.25 4h-11.5c-1.517 0-2.75 1.233-2.75 2.75v14.5c0 1.517 1.233 2.75 2.75 2.75h11.5c1.517 0 2.75-1.233 2.75-2.75v-14.5c0-1.517-1.233-2.75-2.75-2.75zm-2 17h-7.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h7.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-4h-7.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h7.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3.5h-7.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h7.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-4h-7.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h7.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                      </svg>
                    </i>
                  </span>
                  <span className="title" title="View Reports">
                    Reports
                  </span>
                  <span className="arrow">
                    <i className="ti-angle-right" />
                  </span>
                </a>
                <ul className="dropdown-menu">
                  <li
                    className="nav-item "
                    hidden={this.state.hideDashboard === "DISABLED"}
                  >
                    <NavLink
                      to={urlPath + "/dashboard"}
                      className="sidebar-link"
                    >
                      <span className="title" title="View Bot Reports">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  {/* <li
                    className="nav-item "
                    hidden={this.state.hideLogDataModule === "DISABLED"}
                  >
                    <NavLink
                      to={urlPath + "/logmanagement"}
                      className="sidebar-link"
                    >
                      <span className="title" title="View Log Details">
                        Log Management
                      </span>
                    </NavLink>
                  </li> */}
                  <li
                    className="nav-item "
                    hidden={this.state.hideLogDataModule === "DISABLED"}
                  >
                    <NavLink
                      to={urlPath + "/logmanagement"}
                      className="sidebar-link"
                    >
                      <span className="title" title="View Log Details">
                        Log Management
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item "
                    hidden={this.state.hideUserLogDetailsModule === "DISABLED"}
                  >
                    <NavLink
                      to={urlPath + "/logrequestcount"}
                      className="sidebar-link"
                    >
                      <span className="title" title="View Log Details">
                        User Log Details
                      </span>
                    </NavLink>
                  </li>

                  <li
                    className="nav-item "
                    hidden={this.state.hideUserModule === "DISABLED"}
                  >
                    <NavLink
                      to={urlPath + "/userChatDetails"}
                      className="sidebar-link"
                    >
                      <span className="title" title="View User Details">
                        User Chat Details
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item "
                    hidden={this.state.hideLogDataModule === "DISABLED"}
                  >
                    <NavLink
                      to={urlPath + "/trackChanges"}
                      className="sidebar-link"
                    >
                      <span className="title" title="View NLP Audit Reports">
                        Track Changes
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item "
                    hidden={this.state.hideDataArchivalModule === "DISABLED"}
                  >
                    <NavLink
                      to={urlPath + "/dataArchival"}
                      className="sidebar-link"
                    >
                      <span className="title" title="Recieve archived Data">
                        {DATA_ARCHIVAL_PAGE_TITLE}
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown tourBot-second-step">
                <a
                  className="dropdown-toggle"
                // hidden={
                //   this.state.hideStructuredModule === "DISABLED" &&
                //   this.state.hideUnstructuredModule === "DISABLED" &&
                //   this.state.hideBuildHistoryModule === "DISABLED" &&
                //   this.state.hideGPTModule === "DISABLED"
                // }
                >
                  <span className="icon-holder">
                    <i className="c-blue-500 ">
                      <svg
                        id="Layer_1"
                        enableBackground="new 0 0 512 512"
                        height="512"
                        viewBox="0 0 512 512"
                        width="512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <path d="m370 128h-228c-7.72 0-14 6.28-14 14v228c0 7.72 6.28 14 14 14h228c7.72 0 14-6.28 14-14v-228c0-7.72-6.28-14-14-14zm-201.98 232c-8.836 0-16.005-7.164-16.005-16s7.158-16 15.995-16h.01c8.836 0 16 7.164 16 16s-7.164 16-16 16zm0-176c-8.836 0-16.005-7.164-16.005-16s7.158-16 15.995-16h.01c8.836 0 16 7.164 16 16s-7.164 16-16 16zm176 176c-8.836 0-16.005-7.164-16.005-16s7.159-16 15.995-16h.01c8.837 0 16 7.164 16 16s-7.164 16-16 16zm0-176c-8.836 0-16.005-7.164-16.005-16s7.159-16 15.995-16h.01c8.837 0 16 7.164 16 16s-7.164 16-16 16z" />
                          <path d="m496 208c8.837 0 16-7.164 16-16s-7.163-16-16-16h-24v-32h24c8.837 0 16-7.164 16-16s-7.163-16-16-16h-24v-26c0-25.364-20.636-46-46-46h-26v-24c0-8.836-7.163-16-16-16s-16 7.164-16 16v24h-32v-24c0-8.836-7.163-16-16-16s-16 7.164-16 16v24h-32v-24c0-8.836-7.163-16-16-16-8.836 0-16 7.164-16 16v24h-32v-24c0-8.836-7.164-16-16-16s-16 7.164-16 16v24h-32v-24c0-8.836-7.164-16-16-16s-16 7.164-16 16v24h-26c-25.364 0-46 20.636-46 46v26h-24c-8.836 0-16 7.164-16 16s7.164 16 16 16h24v32h-24c-8.836 0-16 7.164-16 16s7.164 16 16 16h24v32h-24c-8.836 0-16 7.164-16 16s7.164 16 16 16h24v32h-24c-8.836 0-16 7.164-16 16s7.164 16 16 16h24v32h-24c-8.836 0-16 7.164-16 16s7.164 16 16 16h24v26c0 25.364 20.636 46 46 46h26v24c0 8.836 7.164 16 16 16s16-7.164 16-16v-24h32v24c0 8.836 7.164 16 16 16s16-7.164 16-16v-24h32v24c0 8.836 7.164 16 16 16 8.837 0 16-7.164 16-16v-24h32v24c0 8.836 7.163 16 16 16s16-7.164 16-16v-24h32v24c0 8.836 7.163 16 16 16s16-7.164 16-16v-24h26c25.364 0 46-20.636 46-46v-26h24c8.837 0 16-7.164 16-16s-7.163-16-16-16h-24v-32h24c8.837 0 16-7.164 16-16s-7.163-16-16-16h-24v-32h24c8.837 0 16-7.164 16-16s-7.163-16-16-16h-24v-32zm-80 162c0 25.364-20.636 46-46 46h-228c-25.364 0-46-20.636-46-46v-228c0-25.364 20.636-46 46-46h228c25.364 0 46 20.636 46 46z" />
                        </g>
                      </svg>
                    </i>
                  </span>
                  <span className="title" title="Train NLP Data">
                    Intelligence
                  </span>
                  <span className="arrow">
                    <i className="ti-angle-right" />
                  </span>
                </a>

                <ul className="dropdown-menu">
                  {sessionStorage.getItem("botType") === "genai" && (
                    <li
                      className="nav-item "
                      hidden={this.state.hideStructuredModule === "DISABLED"}
                    >
                      <NavLink
                        to={urlPath + "/contextualtraining"}
                        className="sidebar-link"
                      >
                        <span className="title" title="Train data">
                          Contextual Training
                        </span>
                      </NavLink>
                    </li>
                  )}
                  <li
                    className="nav-item "
                    hidden={this.state.hideStructuredModule === "DISABLED"}
                  >
                    <NavLink
                      to={urlPath + "/utteranceoperation"}
                      className="sidebar-link"
                    >
                      <span className="title" title="Train Structure Data">
                        Intents
                      </span>
                    </NavLink>
                  </li>

                  {sessionStorage.getItem("botType") !== "genai" && (
                    <li
                      className="nav-item  "
                      hidden={this.state.hideStructuredModule === "DISABLED"}
                    >
                      <NavLink
                        to={urlPath + "/faqupload"}
                        className="sidebar-link"
                      >
                        <span className="title" title="Train FAQ">
                          FAQs
                        </span>
                      </NavLink>
                    </li>
                  )}

                  <li
                    className="nav-item "
                    hidden={this.state.hideBuildHistoryModule === "DISABLED"}
                  >
                    <NavLink
                      to={urlPath + "/BuildHistory"}
                      className="sidebar-link"
                    >
                      <span className="title" title="View Build History">
                        Build History
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown tourBot-third-step">
                <a
                  className="dropdown-toggle"
                // hidden={
                //   this.state.hideApiModule === "DISABLED" &&
                //   this.state.hideChannelModule === "DISABLED"
                // }
                >
                  <span className="icon-holder">
                    <i className="c-blue-500 ">
                      <svg
                        id="Capa_1"
                        enableBackground="new 0 0 512 512"
                        height="512"
                        viewBox="0 0 512 512"
                        width="512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <path d="m256.002 242.913 210.412-121.43-210.412-121.483-210.416 121.483z" />
                          <path d="m240.949 268.986-210.415-121.429v242.96l210.415 121.483z" />
                          <path d="m271.056 268.986v243.014l210.41-121.483v-242.96z" />
                        </g>
                      </svg>
                    </i>
                  </span>
                  <span className="title" title="Integrate with API">
                    Integration
                  </span>
                  <span className="arrow">
                    <i className="ti-angle-right" />
                  </span>
                </a>
                <ul className="dropdown-menu">
                  <li
                    className="nav-item  "
                    hidden={this.state.hideApiModule === "DISABLED"}
                  >
                    <NavLink
                      to={urlPath + "/api-integration"}
                      className="sidebar-link"
                    >
                      <span className="title" title="API Integration">
                        API
                      </span>
                    </NavLink>
                  </li>

                  <li
                    className="nav-item  "
                    hidden={this.state.hideChannelModule === "DISABLED"}
                  >
                    <NavLink
                      to={urlPath + "/channels-integration"}
                      className="sidebar-link"
                    >
                      <span className="title" title="View Deployment Functions">
                        Channels Integration
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown tourBot-fourth-step">
                <a
                  className="dropdown-toggle"
                // hidden={
                //   this.state.hideFormsModule === "DISABLED" &&
                //   this.state.hideConvoModule === "DISABLED" &&
                //   this.state.hideCarouselModule === "DISABLED" &&
                //   this.state.hideDropdownModule === "DISABLED"
                // }
                >
                  <span className="icon-holder">
                    <i className="c-blue-500">
                      <svg
                        height="512pt"
                        viewBox="0 0 512 512"
                        width="512pt"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m346 0h-180c-8.285156 0-15 6.714844-15 15v110c0 8.285156 6.714844 15 15 15h180c8.285156 0 15-6.714844 15-15v-110c0-8.285156-6.714844-15-15-15zm0 0" />
                        <path d="m346 186h-180c-8.285156 0-15 6.714844-15 15v110c0 8.285156 6.714844 15 15 15h180c8.285156 0 15-6.714844 15-15v-110c0-8.285156-6.714844-15-15-15zm0 0" />
                        <path d="m346 372h-180c-8.285156 0-15 6.714844-15 15v110c0 8.285156 6.714844 15 15 15h180c8.285156 0 15-6.714844 15-15v-110c0-8.285156-6.714844-15-15-15zm0 0" />
                        <path d="m457 78h-60c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15h60c13.785156 0 25 11.214844 25 25v60c0 13.785156-11.214844 25-25 25h-23.785156l14.390625-14.394531c5.859375-5.855469 5.859375-15.355469 0-21.210938-5.855469-5.859375-15.355469-5.859375-21.210938 0l-40 40c-5.859375 5.855469-5.859375 15.351563 0 21.210938l40 40c2.925781 2.929687 6.765625 4.394531 10.605469 4.394531s7.679688-1.464844 10.605469-4.394531c5.859375-5.855469 5.859375-15.355469 0-21.210938l-14.390625-14.394531h23.785156c30.328125 0 55-24.671875 55-55v-60c0-30.328125-24.671875-55-55-55zm0 0" />
                        <path d="m85.605469 368.394531c-5.855469-5.859375-15.355469-5.859375-21.210938 0-5.859375 5.855469-5.859375 15.355469 0 21.210938l14.390625 14.394531h-23.785156c-13.785156 0-25-11.214844-25-25v-60c0-13.785156 11.214844-25 25-25h60c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15h-60c-30.328125 0-55 24.671875-55 55v60c0 30.328125 24.671875 55 55 55h23.785156l-14.390625 14.394531c-5.859375 5.855469-5.859375 15.355469 0 21.210938 2.925781 2.929687 6.765625 4.394531 10.605469 4.394531s7.679688-1.464844 10.605469-4.394531l40-40c5.859375-5.855469 5.859375-15.355469 0-21.210938zm0 0" />
                      </svg>
                    </i>
                  </span>
                  <span className="title" title="Design Own Workflow">
                    Workflow
                  </span>
                  <span className="arrow">
                    <i className="ti-angle-right" />
                  </span>
                </a>
                <ul className="dropdown-menu">
                  <li
                    className="nav-item  "
                    hidden={this.state.hideFormsModule === "DISABLED"}
                  >
                    <NavLink to={urlPath + "/Forms"} className="sidebar-link">
                      <span className="title" title="Go to Form">
                        Form
                      </span>
                    </NavLink>
                  </li>

                  <li
                    className="nav-item  "
                    hidden={this.state.hideConvoModule === "DISABLED"}
                  >
                    <NavLink
                      to={urlPath + "/conversationbot"}
                      className="sidebar-link"
                    >
                      <span className="title" title="Go to Conversation">
                        Conversation
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item  "
                    hidden={this.state.hideCarouselModule === "DISABLED"}
                  >
                    <NavLink
                      to={urlPath + "/carouselcreate"}
                      className="sidebar-link"
                    >
                      <span className="title" title="Go to Carousel">
                        Carousel
                      </span>
                    </NavLink>
                  </li>

                  <li
                    className="nav-item  "
                    hidden={this.state.hideDropdownModule === "DISABLED"}
                  >
                    <NavLink
                      to={urlPath + "/DropdownCreate"}
                      className="sidebar-link"
                    >
                      <span className="title" title="Go to Dropdown">
                        Dropdown
                      </span>
                    </NavLink>
                  </li>
                  {/* <li
                    className="nav-item  "
                  >
                    <NavLink
                      to={urlPath + "/smartForm"}
                      className="sidebar-link"
                    >
                      <span className="title" title="Go to Smart Form">
                        Smart Form
                      </span>
                    </NavLink>
                  </li> */}
                </ul>
              </li>

              <li className="nav-item dropdown tourBot-fifth-step">
                <a
                  className="dropdown-toggle"
                // hidden={
                //   this.state.hideThemeModule === "DISABLED" &&
                //   this.state.hideBotSettingsModule === "DISABLED" &&
                //   this.state.hideSystemVariableModule === "DISABLED"
                // }
                >
                  <span className="icon-holder">
                    <i className="c-blue-500">
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 512 512"
                      >
                        <g>
                          <g>
                            <path
                              d="M496.659,312.107l-47.061-36.8c0.597-5.675,1.109-12.309,1.109-19.328c0-7.019-0.491-13.653-1.109-19.328l47.104-36.821
			c8.747-6.912,11.136-19.179,5.568-29.397L453.331,85.76c-5.227-9.557-16.683-14.464-28.309-10.176l-55.531,22.293
			c-10.645-7.68-21.803-14.165-33.344-19.349l-8.448-58.901C326.312,8.448,316.584,0,305.086,0h-98.133
			c-11.499,0-21.205,8.448-22.571,19.456l-8.469,59.115c-11.179,5.035-22.165,11.435-33.28,19.349l-55.68-22.357
			C76.52,71.531,64.04,76.053,58.856,85.568L9.854,170.347c-5.781,9.771-3.392,22.464,5.547,29.547l47.061,36.8
			c-0.747,7.189-1.109,13.44-1.109,19.307s0.363,12.117,1.109,19.328l-47.104,36.821c-8.747,6.933-11.115,19.2-5.547,29.397
			l48.939,84.672c5.227,9.536,16.576,14.485,28.309,10.176l55.531-22.293c10.624,7.659,21.781,14.144,33.323,19.349l8.448,58.88
			C185.747,503.552,195.454,512,206.974,512h98.133c11.499,0,21.227-8.448,22.592-19.456l8.469-59.093
			c11.179-5.056,22.144-11.435,33.28-19.371l55.68,22.357c2.688,1.045,5.483,1.579,8.363,1.579c8.277,0,15.893-4.523,19.733-11.563
			l49.152-85.12C507.838,331.349,505.448,319.083,496.659,312.107z M256.019,341.333c-47.061,0-85.333-38.272-85.333-85.333
			s38.272-85.333,85.333-85.333s85.333,38.272,85.333,85.333S303.08,341.333,256.019,341.333z"
                            />
                          </g>
                        </g>
                      </svg>
                    </i>
                  </span>
                  <span className="title" title="Enhance your Bot">
                    Settings
                  </span>
                  <span className="arrow">
                    <i className="ti-angle-right" />
                  </span>
                </a>

                <ul className="dropdown-menu">
                  <li
                    className="nav-item "
                    hidden={this.state.hideThemeModule === "DISABLED"}
                  >
                    <NavLink
                      to={urlPath + "/themeManagement"}
                      className="sidebar-link"
                    >
                      <span className="title" title="Design own Theme">
                        Theme Management
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item  "
                    hidden={this.state.hideBotSettingsModule === "DISABLED"}
                  >
                    <NavLink
                      to={urlPath + "/BotManagementV1"}
                      className="sidebar-link"
                    >
                      <span className="title" title="Go to Bot Management">
                        Bot Management
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item "
                    hidden={this.state.hideSystemVariableModule === "DISABLED"}
                  >
                    <NavLink
                      to={urlPath + "/CustomVariable"}
                      className="sidebar-link"
                    >
                      <span className="title" title="View System Variables">
                        System Variables
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/* <li className="nav-item dropdown tourBot-sixth-step">
                <a
                  className="dropdown-toggle"
                  hidden={this.state.hideGPTModule === "DISABLED"}
                >
                  <span className="icon-holder">
                    <i className="c-blue-500">
                      <svg
                        width="51"
                        height="51"
                        viewBox="0 0 51 51"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.857117 24.9809V49.7917H18.9776H37.0981V44.592V39.3791L36.0108 39.973C35.3985 40.3029 34.6987 40.6328 34.4487 40.712L33.9738 40.8572V43.6814V46.4924H18.9776H3.98134V24.9809V3.46943H16.3532H28.7251V6.10887V8.74832H31.3495H33.9738V10.6487V12.5491L34.3237 12.6415C34.5112 12.6811 35.1735 12.9846 35.8109 13.3146C36.4482 13.6313 36.9981 13.8952 37.0356 13.8952C37.0731 13.8952 37.0981 12.3644 37.0981 10.4904V7.09867L33.6989 3.62779L30.3123 0.170118H15.5784H0.857117V24.9809Z"
                          fill="#fff"
                        />
                        <path
                          d="M8.10527 11.1106C7.6054 11.6253 7.5804 12.5095 8.03029 13.0638L8.33021 13.4333L12.0543 13.4729C16.1158 13.5125 16.3532 13.4861 16.6906 12.7999C16.9406 12.2852 16.8406 11.4405 16.4782 11.071C16.2282 10.8071 16.0908 10.7939 12.3417 10.7543L8.46768 10.7147L8.10527 11.1106Z"
                          fill="#fff"
                        />
                        <path
                          d="M28.2877 13.0506C23.3265 13.8293 19.365 17.1022 17.6779 21.8532C17.1155 23.45 16.9156 24.651 16.9156 26.6306C16.9156 28.5442 17.0031 29.1248 17.5654 30.9857C18.2153 33.1104 19.2025 34.7337 20.9646 36.5021C23.264 38.8116 25.9133 40.0654 28.9251 40.2501C31.0246 40.3821 33.8114 39.7882 35.5859 38.838C37.248 37.9538 39.3475 35.9874 40.4597 34.2322C45.4834 26.4062 41.5844 15.7561 32.8491 13.3805C31.8119 13.1034 29.1875 12.9054 28.2877 13.0506ZM31.4994 16.5875C33.4739 16.9966 34.9986 17.894 36.6731 19.636C37.6604 20.6654 37.8978 20.9954 38.3852 22.0775C39.1475 23.7272 39.385 24.8226 39.385 26.6306C39.385 28.3594 39.1475 29.4812 38.4727 30.9857C37.2355 33.7175 34.7736 35.8422 32.0368 36.5417C30.7246 36.8716 28.5502 36.8452 27.313 36.4889C23.264 35.288 20.4397 31.6455 20.1523 27.2244C19.9898 24.8885 20.7646 22.2887 22.1768 20.3487C24.3262 17.3925 28.0753 15.888 31.4994 16.5875Z"
                          fill="#fff"
                        />
                        <path
                          d="M33.9738 26.6966V34.4961L34.3487 34.2586C35.0111 33.8495 35.9983 32.8729 36.5607 32.0678L37.098 31.2892V26.7098V22.1171L36.7106 21.51C36.2982 20.837 34.9861 19.5173 34.3612 19.1345L33.9738 18.897V26.6966Z"
                          fill="#fff"
                        />
                        <path
                          d="M22.1768 22.6978C21.8894 23.3312 21.477 24.5718 21.477 24.8093C21.477 24.9413 22.1143 24.9809 24.8636 24.9809H28.2503L28.6127 24.5982C28.9001 24.2946 28.9751 24.0967 28.9751 23.6612C28.9751 22.3151 28.6877 22.2095 25.1886 22.2095H22.4018L22.1768 22.6978Z"
                          fill="#fff"
                        />
                        <path
                          d="M21.2895 27.5148C21.3395 27.9635 21.452 28.5706 21.5269 28.8741L21.6769 29.402H25.0261H28.3752L28.6752 29.0325C29.125 28.4782 29.1001 27.594 28.6127 27.0793L28.2503 26.6965H24.7261H21.202L21.2895 27.5148Z"
                          fill="#fff"
                        />
                        <path
                          d="M22.8016 31.6851C22.7017 31.8567 24.1263 33.4799 24.8011 33.955C25.3135 34.3378 25.376 34.351 26.8506 34.351C28.3252 34.351 28.3752 34.3378 28.6627 34.0078C28.9001 33.7307 28.9751 33.5063 28.9751 33.0312C28.9751 32.2922 28.7251 31.8963 28.1378 31.7247C27.6004 31.5663 22.8891 31.5399 22.8016 31.6851Z"
                          fill="#fff"
                        />
                        <path
                          d="M8.05533 15.6241C7.81789 15.9144 7.73041 16.1784 7.73041 16.6007C7.73041 17.2737 8.00534 17.6828 8.5802 17.8544C8.81764 17.9204 10.6047 17.96 12.5667 17.9468L16.1283 17.9204L16.5032 17.4717C16.8406 17.0758 16.8781 16.957 16.8156 16.4159C16.7656 15.9804 16.6657 15.7296 16.4157 15.5185C16.0908 15.215 16.0658 15.215 12.2293 15.215H8.38025L8.05533 15.6241Z"
                          fill="#fff"
                        />
                        <path
                          d="M7.99281 22.3942C7.26799 22.8165 7.13053 23.9647 7.71788 24.5982L8.08029 24.9809H11.9418H15.7909L16.0033 23.8195C16.1283 23.1729 16.2532 22.5526 16.3032 22.4338C16.3782 22.2227 16.0783 22.2095 12.3417 22.2095C9.21751 22.2227 8.23025 22.2623 7.99281 22.3942Z"
                          fill="#fff"
                        />
                        <path
                          d="M8.03034 26.8285C7.31802 27.132 7.11807 28.3594 7.65543 29.0324L7.95536 29.402L11.9793 29.4416C15.7034 29.4812 16.0033 29.468 15.9284 29.2568C15.8784 29.138 15.8159 28.5046 15.7659 27.8711L15.6909 26.6965L11.9918 26.7097C9.95486 26.7097 8.1678 26.7625 8.03034 26.8285Z"
                          fill="#fff"
                        />
                        <path
                          d="M8.29279 31.6851C7.30554 32.0282 7.03061 33.1632 7.71794 33.9814L8.08034 34.4169L12.7292 34.4565C15.291 34.4829 17.4655 34.4697 17.5655 34.4301C17.7029 34.3641 17.6779 34.2454 17.428 33.7967C17.2655 33.4799 17.0031 32.8992 16.8407 32.5033C16.6782 32.1074 16.4907 31.7379 16.4283 31.6851C16.2658 31.5663 8.6427 31.5663 8.29279 31.6851Z"
                          fill="#fff"
                        />
                        <path
                          d="M39.6224 38.1781L38.2103 39.6034L42.8591 44.7372C45.4085 47.5482 47.5954 49.9633 47.7079 50.082C47.8953 50.2932 48.0078 50.214 49.3825 48.8283L50.8571 47.3502L46.0333 42.0317C43.3715 39.1151 41.1595 36.7264 41.122 36.7264C41.0721 36.7396 40.3972 37.3863 39.6224 38.1781Z"
                          fill="#fff"
                        />
                        <path
                          d="M20.2898 39.1284C19.9024 39.4979 19.7524 40.2501 19.9774 40.8176C20.2773 41.5698 20.4772 41.6094 24.4387 41.6094C27.8254 41.5962 28.5002 41.5171 27.1006 41.2795C25.7259 41.042 23.264 39.9862 22.0393 39.1152C21.5145 38.7324 20.7147 38.7456 20.2898 39.1284Z"
                          fill="#fff"
                        />
                      </svg>
                    </i>
                  </span>
                  <span className="title" title="Enhance your Bot">
                    POCs
                  </span>
                  <span className="arrow">
                    <i className="ti-angle-right" />
                  </span>
                </a>

                <ul className="dropdown-menu">
                  <li
                    className="nav-item "
                    hidden={this.state.hideGPTModule === "DISABLED"}
                  >
                    <NavLink to={urlPath + "/chatgpt"} className="sidebar-link">
                      <span className="title" title="GPT Training">
                        GPT Training
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </li> */}
              <hr />
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(sidebar);
