import { Navigate } from "react-router-dom";
import { urlPath } from "../../configurations/ServerCreds";
import store from "../../redux/store";
import { toast_notify } from "../ToastServices";
import UserService from "../UserService";

function PrivateRoute({ children, ...rest }) {
  const storeData = store.getState();
  let access = true;
  let accessVal = true;
  if (
    storeData &&
    storeData.fetchDataSlice &&
    storeData.fetchDataSlice.data &&
    storeData.fetchDataSlice.data.userRolePermissionData
  ) {
    const dataPermission =
      storeData.fetchDataSlice.data.userRolePermissionData.filter(
        (item) => item.moduleName === rest.moduleName
      );
    if (dataPermission[0].readPermission) {
      access = true;
    } else {
      access = false;
      toast_notify("You are not authorized to view this page", "error");
    }
  }

  if (
    storeData &&
    storeData.fetchDataSlice &&
    storeData.fetchDataSlice.data &&
    storeData.fetchDataSlice.data.botData &&
    storeData.fetchDataSlice.data.botData.length === 0 && rest.moduleName !== "Homepage"
  ) {
      accessVal=false;
  }

  return (
    accessVal ?
    localStorage.getItem("loggined") === "true" && access ? (
        <>{children}</>
    ) : (
      <Navigate
        to={{
          pathname: `${urlPath}/login`,
        }}
      />
    )
    :
    <Navigate
        to={{
          pathname: `${urlPath}/botlist`,
        }}
      />
  );
}

export default PrivateRoute;