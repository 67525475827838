export var urlPath = "/bmpadmin";
export var USER_GUIDE_URL = "https://botzer.lntinfotech.com/userguide/";
export var BASE_URL = "https://botzer.lntinfotech.com/bmpadmin";
export var BMP_FE = "https://botzer.lntinfotech.com/bmpadmin/";
export var BMP_BE = "https://botzer.lntinfotech.com/bmpapi/";
export var CHATBOT_URL = "https://botzer.lntinfotech.com/chatbot/chatpage?botId=";
export var logout_server ="https://keycloak-botzer.lntinfotech.com/auth/realms/master/protocol/openid-connect/logout?redirect_uri=" + BMP_FE;
export var CURRENT_SERVER = "https://botzer.lntinfotech.com/bmpapi/";
export var newtablink = "http://localhost:8082/chatbot/chatpage?botId=";
export var liveAgentAPI = "https://botzer.lntinfotech.com/live-agentapi/";
export var CHAT_BACKEND_APP_URL = "http://34.207.105.149:8084/";
export var adminrole = "1";
export var developerrole = "2";
export var business_user = "4";
export var VOICE_API = "https://botzer.lntinfotech.com/lexapi/";
export var languageCode = "en";
export var domain_url = "https://botzer.lntinfotech.com/";
export var s3_url = "https://botzer-s3.s3.ap-south-1.amazonaws.com/samplefile/";
USER_GUIDE_URL = "https://botzer.lntinfotech.com/userguide/bmp";
export var S3_Image_URL = "https://botzer-s3.s3.ap-south-1.amazonaws.com";
