import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import fetchDataSliceReducer from "../component/AuthenticationModule/fetchDataSlice";
import CryptoJS from "crypto-js";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  createTransform,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import feedbackFormSliceReducer from "../component/BotSettings/FeedbackFormComponents/feedbackFormSlice";
import fetchDataSlice from "../component/AuthenticationModule/fetchDataSlice";

const encrypt = createTransform(
  (inboundState, key) => {
    if (!inboundState) return inboundState;
    const cryptedText = CryptoJS.AES.encrypt(
      JSON.stringify(inboundState),
      process.env.REACT_APP_REDUX_KEY
    );

    return cryptedText.toString();
  },
  (outboundState, key) => {
    if (!outboundState) return outboundState;
    const bytes = CryptoJS.AES.decrypt(
      outboundState,
      process.env.REACT_APP_REDUX_KEY
    );
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);

    return JSON.parse(decrypted);
  }
);
const persistConfig = {
  key: "rootBMP",
  version: 1,
  storage,
  whitelist: ["fetchDataSlice"],
  transforms: [encrypt],
};

const reducer = combineReducers({
  feedbackFormSlice: feedbackFormSliceReducer,
  fetchDataSlice: fetchDataSliceReducer,
});
const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  // devTools: process.env.NODE_ENV !== 'production',
});

export default store;
