import "babel-polyfill";
import "react-app-polyfill/ie11";
import { polyfill } from "es6-promise";
import React, { Component, Suspense, lazy } from "react";
import "./App.css";
import "./API/AxiosIndex.js";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.js";
import "react-datepicker/dist/react-datepicker.css";
import {
  BrowserRouter,
  Route,
  Navigate,
  Routes,
  NavLink,
} from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  CURRENT_SERVER,
  CHATBOT_URL,
  BMP_BE,
} from "./configurations/ServerCreds";

import axios from "axios";

import { developerrole, urlPath } from "./configurations/ServerCreds";

import { Modal } from "react-bootstrap";
import { ScrollBarContext } from "./context/scrollbar-context";
import { setStoreData } from "./component/AuthenticationModule/fetchDataSlice";
import { connect } from "react-redux";
import { toast_notify } from "./services/ToastServices";
import store from "./redux/store";
import IdleTimerCount from "./services/Timer";
import PrivateRoute from "./services/PrivateRoute/PrivateRoute";
import { Buffer } from "buffer";
import { IdleTimer } from "./component/IdleTimer/idleTimer.jsx";
import NotificationAPI from "./API/NotificationAPI";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "./component/Header-SideBar Module/BotSideBar";
import UserService from "./services/UserService";
import AuthenticateModuleAPI from "./API/AuthenticateModuleAPI";
import { triggerNavigation } from "./services/PubSubCommunication.js";
import withRouter from "./component/withRouter/withRouter.jsx";
import { ToastContainer } from "react-toastify";
import ContextualTraining from "./component/ContextualTrainingModule/ContextualTraining/ContextualTraining.jsx";

polyfill();
require("es6-object-assign").polyfill();
const LoginPage = lazy(() =>
  import("./component/AuthenticationModule/LoginPage")
);
const Header = lazy(() => import("./component/Header-SideBar Module/header"));
const Dashboard = lazy(() => import("./container/dashboard-pages/dashboard"));
const Bot_list = lazy(() => import("./container/bot-pages/bot_creation"));
const RoleCreation = lazy(() =>
  import("./component/User & Role Module/RoleManagement")
);
const ViewPermission = lazy(() =>
  import("./component/User & Role Module/ViewPermission")
);
const CreateUser = lazy(() => import("./container/users-page/usercreation"));
const UserChatInfo = lazy(() => import("./component/Logs Module/chatUserInfo"));
const Channels = lazy(() => import("./container/chatwindow-details/channels"));
const ChannelsIntegration = lazy(() =>
  import("./container/chatwindow-details/channels-integration")
);
const FaqsDataList = lazy(() => import("./component/FaqsDataList"));
const ThemeManagement = lazy(() =>
  import("./component/BotSettings/themeManagement")
);
const BotManagement = lazy(() =>
  import("./component/BotSettings/BotManagementV1")
);
const CarouselCreate = lazy(() =>
  import("./component/Carousel Module/carousel_create")
);
const DropdownCreate = lazy(() =>
  import("./component/Dropdown Module/DropdownCreate")
);
const UserQueries = lazy(() => import("./container/user-queries/UserQueries"));
const ChatLoginuserInfo = lazy(() =>
  import("./component/Logs Module/chatbotloginfo")
);
const UserFeedbackDataInfo = lazy(() =>
  import("./component/Logs Module/userFeedbackDataList")
);
const FAQFeedbackInfo = lazy(() =>
  import("./component/Logs Module/faqFeedbackInfo")
);
const ConversationBot = lazy(() =>
  import("./component/ConversationPage/conversation_bot")
);
const CreateTask = lazy(() =>
  import("./component/API Integration Module/CreateTask")
);
const MainNlp = lazy(() => import("./component/NLP Module/NlpMainPage"));
const Overview = lazy(() =>
  import("./component/Workflow_Preview/Workflowpreview")
);
const BuildHistory = lazy(() => import("./component/BuildHistory.js"));
const FaqUpload = lazy(() => import("./component/faqUpload"));
const MainFormsPage = lazy(() => import("./component/Dialog Module/MainForms"));
const LogRequestCount = lazy(() =>
  import("./component/Logs Module/LogRequestDataList.js")
);
const CustomVariable = lazy(() =>
  import("./component/CustomVariableDataList.js")
);
const LogMainPage = lazy(() => import("./component/Logs Module/logMainpage"));
const TrackChanges = lazy(() => import("./component/TrackChanges"));
const ForgetPassword = lazy(() =>
  import("./component/AuthenticationModule/ForgetPassword")
);
const ResetPassword = lazy(() =>
  import("./component/AuthenticationModule/ResetPassword")
);
const SignUp = lazy(() =>
  import("./component/AuthenticationModule/SignUpPage")
);
const GPTTraining = lazy(() =>
  import("./component/POCs/GPTTraining/GPTTraining")
);
const UserChatDetailsWrap = lazy(() =>
  import("./component/Logs Module/UserChatDetailsWrap")
);
const DataArchival = lazy(() =>
  import("./component/DataArchival/DataArchival")
);

const PageNotFound = () => {
  return (
    <React.Fragment>
      <h1 className="text-center">
        This page does not exist. &nbsp;
        <NavLink exact to={urlPath + "/login"}>
          Go back to Home
        </NavLink>
      </h1>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  data: state.fetchDataSlice.data,
});
const mapDispatchToProps = (dispatch) => ({
  setStoreData: (data) => dispatch(setStoreData(data)),
});

class App extends Component {
  scrollBarRef;
  unsubPubNavigate = null;
  constructor(props) {
    super(props);
    this.loginRef = React.createRef();
    this.header = React.createRef();
    this.state = {
      showLogoutModal: false,
      loggined: false,
      isBusinessUser: false,
      userRolePermissionData: [],
      token: "",
      sessionOut: false,
      login: false,
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.loginFunc = this.loginFunc.bind(this);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.pause = this._pause.bind(this);
    this.reset = this._reset.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.updateScrollSize = this.updateScrollSize.bind(this);
    this.unsubPubNavigate = triggerNavigation.subscribe((redirectto) => {
      if (redirectto && redirectto === "/login") {
        this.props.navigate(0);
      } else {
        redirectto && this.props.navigate(urlPath + redirectto);
      }
    });
  }

  async componentWillMount() {
    sessionStorage.setItem("CHATBOT_URL", CHATBOT_URL);
    sessionStorage.setItem("BACKEND_URL", BMP_BE);
    await this.loginFunc();
    await this.handleLoad();
  }
  async componentWillUnmount() {
    this.unsubPubNavigate && this.unsubPubNavigate.unsubscribe();
  }
  async loginFunc() {
    const storeData = store.getState();
    sessionStorage.setItem(
      "bmpsessionId",
      storeData.fetchDataSlice.data.sessionId
    );
    if (
      storeData.fetchDataSlice &&
      storeData.fetchDataSlice.data &&
      storeData.fetchDataSlice.data.authData
    ) {
      const data = storeData.fetchDataSlice.data.authData;
      if (localStorage.getItem("loggined") && data != undefined) {
        sessionStorage.setItem("mainUserID", data.mainUserId);
        sessionStorage.setItem("userId", JSON.stringify(data));
        sessionStorage.setItem("emailid", storeData.fetchDataSlice.data.email);
        sessionStorage.setItem("rolename", data.roleId);
        sessionStorage.setItem("userName", data.userName);
        sessionStorage.setItem("liveAgentAdminId", data.liveAgentAdminId);
        sessionStorage.setItem("Loggined", true);
        this.setState({
          userRolePermissionData:
            storeData.fetchDataSlice.data.userRolePermissionData,
          loggined: true,
        });
        this.handleScript();
        if (sessionStorage.getItem("botId")) {
          NotificationAPI.fetchNotificationData(
            sessionStorage.getItem("botId")
          ).then((response) => {
            response.data.notificationList &&
              this.handleNotificationDataParent(response.data);
          });
        }
      }
    }
  }

  handleScript = () => {
    (function () {
      var f3 = document.createElement("script"),
        f4 = document.getElementsByTagName("script")[0];
      f3.async = true;
      f3.src = "/bmpadmin/assets/js/scriptloader.js";
      f3.charset = "UTF-8";
      f3.setAttribute("crossorigin", "*");
      f4.parentNode.insertBefore(f3, f4);
    })();
  };

  async handleLoad() {
    // (function () {
    //   var f3 = document.createElement("script"),
    //     f4 = document.getElementsByTagName("script")[0];
    //   f3.async = true;
    //   f3.src = "/bmpadmin/assets/js/scriptloader.js";
    //   f3.charset = "UTF-8";
    //   f3.setAttribute("crossorigin", "*");
    //   f4.parentNode.insertBefore(f3, f4);
    // })();
    sessionStorage.setItem("loader", document.getElementById("spinner-loader"));
    const loader = document.getElementById("spinner-loader");
    setTimeout(() => {
      loader.classList.add("fadeOut");
    }, 100);

    $(".search-toggle").on("click", (e) => {
      $(".search-box, .search-input").toggleClass("active");
      $(".search-input input").focus();
      e.preventDefault();
    });

    $(".sidebar .sidebar-menu li a").on("click", function () {
      const $this = $(this);

      if ($this.parent().hasClass("open")) {
        $this

          .parent()
          .children(".dropdown-menu")
          .slideUp(200, () => {
            $this.parent().removeClass("open");
          });
      } else {
        $this
          .parent()
          .parent()
          .children("li.open")
          .children(".dropdown-menu")
          .slideUp(200);

        $this
          .parent()
          .parent()
          .children("li.open")
          .children("a")
          .removeClass("open");

        $this.parent().parent().children("li.open").removeClass("open");

        $this
          .parent()
          .children(".dropdown-menu")
          .slideDown(200, () => {
            $this.parent().addClass("open");
          });
      }
    });

    // Sidebar Activity Class
    const sidebarLinks = $(".sidebar").find(".sidebar-link");
    sidebarLinks
      .each((index, el) => {
        $(el).removeClass("active");
      })
      .filter(function () {
        const href = $(this).attr("href");
        const pattern = href[0] === "/" ? href.substr(1) : href;
        return pattern === window.location.pathname.substr(1);
      })
      .addClass("active");

    // ٍSidebar Toggle
    $(".sidebar-toggle").on("click", (e) => {
      $(".app").toggleClass("is-collapsed");
      e.preventDefault();
    });
  }

  //-----------------Handling Idle timer-----------------------
  _onAction(e) {
    // console.log("user did something", e);
  }

  _onActive(e) {
    // console.log("user is active", e);
    // console.log("time remaining", this.idleTimer.getRemainingTime())
  }
  _pause() {
    this.idleTimer.pause();
  }
  _reset() {
    this.idleTimer.reset();
  }
  _onIdle(e) {
    this.setState({
      // logout popup opens
      showLogoutModal: true,
    });
    this._pause();
  }

  autoLogout(dologout) {
    if (dologout) {
      UserService.handleLogout();
    }
  }

  continueWorking = () => {
    this._reset();
    this.setState(
      {
        showLogoutModal: false,
      },
      () => {
        if (this.props.data != undefined) {
          const refreshToken = this.props.data.tokenData.refresh_token;
          const encodedCode = Buffer.from(
            `${process.env.REACT_APP_CLIENT_ID}:${process.env.REACT_APP_CLIENT_SECRET}`
          ).toString("base64");
          var basicAuth = `Basic ${encodedCode}`;
          let data = {
            grant_type: "refresh_token",
            refresh_token: refreshToken,
          };
          let config = {
            headers: {
              Authorization: basicAuth,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          };
          AuthenticateModuleAPI.authenticateUser(data, config)
            .then((response) => {
              if (response.status == "200") {
                this.props.setStoreData({ tokenData: response.data });
                axios.defaults.headers.common["Authorization"] =
                  "Bearer " + this.props.data.tokenData.access_token;
              }
            })
            .catch((error) => {
              toast_notify("Something went wrong", "error");
            });
        }
      }
    );
  };

  sendBotNametoHeader = (botName) => {
    this.refs.header && this.refs.header.callParent(botName);
  };

  handleNotificationDataParent = (dataNotification) => {
    this.refs.header &&
      this.refs.header.handleNotificationData(dataNotification);
  };

  closeModal = () => {
    this.setState({
      showSuccess: false,
    });
  };

  updateScrollSize() {
    if (this.scrollBarRef) {
      this.scrollBarRef.updateScroll();
    }
  }
  getUserRolePermission = async () => {
    const roleId = sessionStorage.getItem("rolename")
      ? sessionStorage.getItem("rolename")
      : "4";
    const URL = `${CURRENT_SERVER}permission/v2/getByRole/${roleId}`;

    const res = await axios.get(URL);
    this.setState(
      {
        userRolePermissionData: [...res.data],
        loggined: true,
      },
      () => {
        this.props.setStoreData({
          userRolePermissionData: this.state.userRolePermissionData,
        });
        this.handleLoad();
      }
    );
    return res.data;
  };

  render() {
    const rolename = sessionStorage.getItem("rolename");
    const loggined = localStorage.getItem("loggined");
    return (
      <React.Fragment>
        <ToastContainer></ToastContainer>
        <ScrollBarContext.Provider
          value={{
            updateScroll: this.updateScrollSize,
            userRolePermission: this.state.userRolePermissionData,
          }}
        >
          <div id="spinner-loader">
            <div className="spinner"></div>
          </div>
          {loggined && <Sidebar></Sidebar>}
          <Suspense
            fallback={
              <div id="spinner-loader">
                <div className="spinner"></div>
              </div>
            }
          >
            {loggined && (
              <React.Fragment>
                <IdleTimer
                  ref={(ref) => {
                    this.idleTimer = ref;
                  }}
                  element={document}
                  onActive={this.onActive}
                  onIdle={this.onIdle}
                  onAction={this.onAction}
                  timeout={600000}
                />
                {/* <Sidebar></Sidebar> */}
              </React.Fragment>
            )}
            <div className="page-container">
              {sessionStorage.getItem("Loggined") && (
                <Header ref="header"></Header>
              )}

              <main className="main-content bgc-grey-100">
                <div id="mainContent">
                  <div className="notification_toast"></div>
                  <div className="full-container">
                    <Modal
                      centered
                      show={this.state.showLogoutModal}
                      onHide={this.state.handleCloseLogout}
                    >
                      <Modal.Header>
                        <Modal.Title>Session Timeout Warning</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {" "}
                        Hey, you are idle for a while and your session will
                        expire in{" "}
                        <IdleTimerCount autoLogout={this.autoLogout} />
                        Choose one from below options.{" "}
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => {
                            this.continueWorking();
                          }}
                        >
                          Yes I'm In
                        </button>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => {
                            UserService.handleLogout();
                          }}
                        >
                          Take me out
                        </button>
                      </Modal.Footer>
                    </Modal>
                    <PerfectScrollbar
                      ref={(ref) => {
                        this.scrollBarRef = ref;
                      }}
                    >
                      <Routes>
                        <Route
                          exact
                          path={urlPath + "/"}
                          element={
                            !loggined ? (
                              <Navigate to={urlPath + "/login"} />
                            ) : (
                              <Navigate to={urlPath + "/botlist"} />
                            )
                          }
                        />
                        <Route
                          exact
                          path={urlPath + "/login"}
                          element={
                            <LoginPage
                              getUserRolePermission={this.getUserRolePermission}
                              handleScript={this.handleScript}
                            />
                          }
                        />

                        <Route
                          exact
                          path={urlPath + "/signUp"}
                          element={!loggined ? <SignUp /> : <LoginPage />}
                        />

                        <Route
                          exact
                          path={urlPath + "/forgotpassword"}
                          element={
                            !loggined ? <ForgetPassword /> : <LoginPage />
                          }
                        />

                        <Route
                          exact
                          path={urlPath + "/setpassword"}
                          element={
                            !loggined ? <ResetPassword /> : <LoginPage />
                          }
                        />
                        <Route
                          path={urlPath + "/chatgpt"}
                          element={<GPTTraining />}
                        />

                        <Route
                          path={urlPath + "/botlist"}
                          exact
                          element={
                            <PrivateRoute moduleName="Homepage">
                              <Bot_list />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          exact
                          path={urlPath + "/dashboard"}
                          element={
                            <PrivateRoute moduleName="Dashboard">
                              <Dashboard
                                sendBotNametoHeader={this.sendBotNametoHeader}
                                handleNotificationDataParent={
                                  this.handleNotificationDataParent
                                }
                              />
                            </PrivateRoute>
                            // loggined ? (
                            //   <Dashboard
                            //     sendBotNametoHeader={this.sendBotNametoHeader}
                            //     handleNotificationDataParent={
                            //       this.handleNotificationDataParent
                            //     }
                            //   />
                            // ) : (
                            //   <Navigate
                            //     to={{
                            //       pathname: `${urlPath}/login`,
                            //     }}
                            //   />
                            // )
                          }
                        />

                        <Route
                          path={urlPath + "/Forms"}
                          element={
                            <PrivateRoute moduleName="Workflows">
                              <MainFormsPage />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={urlPath + "/conversationbot"}
                          element={
                            <PrivateRoute moduleName="Workflows">
                              <ConversationBot />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          exact
                          path={urlPath + "/utteranceoperation"}
                          element={
                            <PrivateRoute moduleName="Intelligence">
                              <MainNlp />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          exact
                          path={urlPath + "/api-integration"}
                          element={
                            <PrivateRoute moduleName="API Integration">
                              <CreateTask />
                            </PrivateRoute>
                          }
                        />

                        {rolename === developerrole &&
                        window.location.pathname === "/createuser" ? (
                          <Navigate to={urlPath + "/"} />
                        ) : (
                          <Route
                            exact
                            path={urlPath + "/createuser"}
                            element={
                              <PrivateRoute moduleName="Access Management">
                                <CreateUser />
                              </PrivateRoute>
                            }
                          />
                        )}

                        {/* {rolename === developerrole &&
                            window.location.pathname === "/LoginLogout" ? (
                            <Navigate to={urlPath + "/"} />
                          ) : (
                            <Route
                              exact
                              path={urlPath + "/LoginLogout"}
                              element={LoginLogout}
                            />
                          )} */}

                        <Route
                          exact
                          path={urlPath + "/userqueries"}
                          element={
                            <PrivateRoute moduleName="Reporting">
                              <UserQueries />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          exact
                          path={urlPath + "/DropdownCreate"}
                          element={
                            <PrivateRoute moduleName="Workflows">
                              <DropdownCreate />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={urlPath + "/RoleCreation"}
                          element={
                            <PrivateRoute moduleName="Access Management">
                              <RoleCreation />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={urlPath + "/ViewPermission"}
                          element={
                            <PrivateRoute moduleName="Access Management">
                              <ViewPermission />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          exact
                          path={urlPath + "/logmanagement"}
                          element={
                            <PrivateRoute moduleName="Reporting">
                              <LogMainPage />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          exact
                          path={urlPath + "/logrequestcount"}
                          element={
                            <PrivateRoute moduleName="Reporting">
                              <LogRequestCount />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          exact
                          path={urlPath + "/carouselcreate"}
                          element={
                            <PrivateRoute moduleName="Workflows">
                              <CarouselCreate />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          exact
                          path={urlPath + "/chatuserinfo"}
                          element={
                            <PrivateRoute moduleName="Reporting">
                              <UserChatInfo />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          exact
                          path={urlPath + "/channels"}
                          element={
                            <PrivateRoute moduleName="Channel Integration">
                              <Channels />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          exact
                          path={urlPath + "/BuildHistory"}
                          element={
                            <PrivateRoute moduleName="Intelligence">
                              <BuildHistory />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          exact
                          path={urlPath + "/channels-integration"}
                          element={
                            <PrivateRoute moduleName="Channel Integration">
                              <ChannelsIntegration />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          exact
                          path={urlPath + "/Workflowpreview"}
                          element={
                            <PrivateRoute moduleName="Workflows">
                              <Overview />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          exact
                          path={urlPath + "/themeManagement"}
                          element={
                            <PrivateRoute moduleName="Settings">
                              <ThemeManagement />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          exact
                          path={urlPath + "/BotManagementV1"}
                          element={
                            <PrivateRoute moduleName="Settings">
                              <BotManagement />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          exact
                          path={urlPath + "/CustomVariable"}
                          element={
                            <PrivateRoute moduleName="Settings">
                              <CustomVariable />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          exact
                          path={urlPath + "/userChatDetails"}
                          element={
                            <PrivateRoute moduleName="Reporting">
                              <UserChatDetailsWrap />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={urlPath + "/faqsdata"}
                          element={
                            <PrivateRoute moduleName="Intelligence">
                              <FaqsDataList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={urlPath + "/faqupload"}
                          element={
                            <PrivateRoute moduleName="Intelligence">
                              <FaqUpload />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={urlPath + "/trackChanges"}
                          element={
                            <PrivateRoute moduleName="Reporting">
                              <TrackChanges />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={urlPath + "/dataArchival"}
                          element={
                            <PrivateRoute moduleName="Reporting">
                              <DataArchival />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={urlPath + "/contextualtraining"}
                          element={
                            <PrivateRoute moduleName="Intelligence">
                              <ContextualTraining />
                            </PrivateRoute>
                          }
                        />

                        <Route path="*" element={<PageNotFound />} />
                      </Routes>
                    </PerfectScrollbar>
                  </div>
                </div>
              </main>
            </div>
          </Suspense>
        </ScrollBarContext.Provider>
      </React.Fragment>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
