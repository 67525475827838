import * as AppConstants from "../../../configurations/AppConstants.js";
import React, { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Swal from "sweetalert2";
import { ScrollBarContext } from "../../../context/scrollbar-context.js";
import { TableLoader } from "../../../container/ContentLoaderComponent/TableLoader.jsx";
import Select from "react-select";
import { permissionServices } from "../../../services/PermissionServices.js";

const TrainURL = () => {
  const [tableData, setTableData] = useState([]);
  //   let { userRolePermission } = useContext(ScrollBarContext);
  const [apiLoader, setApiLoader] = useState(false);

  const [tablePageCount, setTablePageCount] = useState(0);
  const [tableRowPerPageCount, setTableRowPerPageCount] = useState(10);
  let count = 10;
  let page = 0;
  const [totalElements, setTotalElements] = useState(0);

  //   //permission for downloading
  //   useEffect(() => {
  //     (function getPermission() {
  //       let obj = {
  //         moduleName: "Intelligence",
  //         userPermissionData: userRolePermission,
  //       };

  //       permissionServices(obj).then((res) => {
  //         // setDisableDownload(res.read);
  //       });
  //     })();
  //   }, []);

  //column names would be stored from first object
  let getColumns = () => {
    if (tableData.length) {
      return Object.keys(tableData[0]).map((key) => {
        return {
          name: key,
          label: key,
          options: {
            customBodyRender: (value) => {
              return value === "" ? "-" : value;
            },
          },
        };
      });
    } else {
      return [
        {
          name: "fileName",
          label: "File Name",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "uploadDetails",
          label: "Upload Details",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "action",
          label: "Action",
          options: {
            filter: true,
            sort: false,
          },
        },
      ];
    }
  };

  const columns = getColumns();

  //theme styling
  const getMuiTheme = () => {
    return createTheme({
      typography: {
        useNextVariants: true,
      },
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            fixedHeader: {
              padding: "0px !important",
              fontSize: "0.750rem!important",
            },
            data: {
              textTransform: "none",
              fontSize: "0.750rem!important",
            },
            toolButton: {
              marginLeft: "0px!important",
              paddingLeft: apiLoader ? "0px!important" : "15px!important",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              padding: "0px !important",
            },
          },
        },
        MUIDataTablePagination: {
          styleOverrides: {
            root: {
              "&:last-child": {
                padding: "0px 24px 0px 0px",
              },
            },
            navContainer: {
              float: "left",
            },
            toolbar: {
              paddingLeft: apiLoader ? "0px!important" : "16px!important",
              opacity: "0.7",
              ">*": {
                fontSize: "0.750rem!important",
              },
            },
            tableCellContainer: {
              padding: "0px 24px 0px 0px!important",
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              borderBottom: "0px",
            },
          },
        },
      },
    });
  };

  const options = {
    ...AppConstants.DATATABLE_DEFAULT_OPTIONS,
    selectableRowsOnClick: false,
    selectedRows: false,
    print: false,
    search: false,
    download: false,
    viewColumns: false,
    filter: false,
    count: totalElements,
    // rowsPerPage: 10,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: apiLoader ? (
          <TableLoader />
        ) : (
          "Sorry, no matching records were found"
        ),
      },
    },

    pagination: tableData.length ? true : false,
    onTableChange: (action, tableState) => {
      if (action === "propsUpdate") {
        tableState.page = tablePageCount;
        tableState.rowsPerPage = tableRowPerPageCount;
      }
      switch (action) {
        case "changePage":
          setTablePageCount(tableState.page);
          setTableRowPerPageCount(tableState.rowsPerPage);

          break;
        case "changeRowsPerPage":
          setTablePageCount(tableState.page);
          setTableRowPerPageCount(tableState.rowsPerPage);

          break;
        default:
          console.log("action not supported");
      }
    },
  };

  return (
    <div className="peers fxw-nw pos-r">
      <div className="" id="main-window-scroll">
        <div className="table-container datatable">
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              // title={"Track Changes"}
              data={tableData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default TrainURL;
