import { Subject } from 'rxjs';

///////////////////////////////////////// Dropdown ////////////////////////////////////////////////
export const DropdownAddEditDialog = new Subject();
export const PubDropdownAddEditDialog = (data) => DropdownAddEditDialog.next(data);

export const DropdownElementAddEditDialog = new Subject();
export const PubDropdownElementAddEditDialog = (data) => DropdownElementAddEditDialog.next(data);

export const DropdownBuldAddDialog = new Subject();
export const PubDropdownBuldAddDialog = (data) => DropdownBuldAddDialog.next(data);

//////////////////////////////////////// Conversation ///////////////////////////////////////////////

export const ConversationAddEditCloneDialog = new Subject();
export const PubConversationAddEditCloneDialog = (data) => ConversationAddEditCloneDialog.next(data);

export const ConversationAddEditCloneSubmit = new Subject();
export const PubConversationAddEditCloneSubmit = (data) => ConversationAddEditCloneSubmit.next(data);

export const ConversationGlobalVariablesDialog = new Subject();
export const PubConversationGlobalVariablesDialog = (data) => ConversationGlobalVariablesDialog.next(data);

export const ConversationQuestionsForm = new Subject();
export const PubConversationQuestionsForm = (data) => ConversationQuestionsForm.next(data);

export const ConversationUpdateQuestions = new Subject();
export const PubConversationUpdateQuestions = (data) => ConversationUpdateQuestions.next(data);

export const ConversationUpdateConversation = new Subject();
export const PubConversationUpdateConversation = (data) => ConversationUpdateConversation.next(data);


//////////////////////////////////////// NLP - Structured Data ///////////////////////////////////////////////

export const NLPEntitiesUpdates = new Subject();
export const PubNLPEntitiesUpdates = (data) => NLPEntitiesUpdates.next(data);

//////////////////////////////////////// Navigation ///////////////////////////////////////////////////
export const triggerNavigation = new Subject();
export const PubtriggerNavigation = (data) => triggerNavigation.next(data);