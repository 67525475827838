import React from 'react';
import ContentLoader from 'react-content-loader';

const MemoTableLoader = (props) => {
    const size = typeof props === 'object' && props.hasOwnProperty('size') && typeof props.size === 'number' ? props.size : 11;
    const calculateHeight = (size * 30) + ((size - 1) * 5) + 20;
    return (
        <ContentLoader
            height={calculateHeight} width={'100%'}
        >
            { 
                [...Array(size)].map((_, value) => {
                    const yPos = (value * 30) + (value === 0 ? 10 : ((value * 5) + 10));
                    return (
                        <rect key={yPos} x="0" y={yPos} rx="5" ry="5" width="100%" height="30" />
                    )
                })
            }
        </ContentLoader>
    );
};

export const TableLoader = React.memo(MemoTableLoader);