import { createSlice } from "@reduxjs/toolkit";

const fetchDataSlice = createSlice({
    name: "fetchData",
    initialState: {
        data: {}
    },
    reducers: {
        setStoreData(state, { payload }) {
            for (const [key, value] of Object.entries(payload)) {
                state.data[key] = value
            }
        }
    }
})

export const { setStoreData } = fetchDataSlice.actions;
export default fetchDataSlice.reducer;



